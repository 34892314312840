import { type EventPayloads } from "./types";
import { tagular } from "./index";

// Setup tracking for different types of events
document.addEventListener("DOMContentLoaded", () => {
  const tagularBody = document.getElementById("tagularBody");

  // Track an event with the tagular function
  function trackEvent<T extends keyof EventPayloads>(
    eventName: T,
    payload: EventPayloads[T]
  ): void {
    tagular(eventName, payload);
  }

  // Setup tracking for click events
  function setupClickTracking(htmlElement: HTMLElement) {
    htmlElement.addEventListener("click", (event: MouseEvent) => {
      let targetElement = event.target as HTMLElement;

      while (
        targetElement &&
        targetElement !== htmlElement &&
        !targetElement.dataset.tagular
      ) {
        targetElement = targetElement.parentNode as HTMLElement;
      }

      if (
        targetElement &&
        targetElement.dataset.tagular &&
        !targetElement.hasAttribute("data-view-track")
      ) {
        try {
          const data = JSON.parse(targetElement.dataset.tagular);
          const { event: eventName, ...restPayload } = data; // Destructure to get eventName and the rest of the payload
          trackEvent(eventName, restPayload); // Pass the eventName and payload to trackEvent
        } catch (error) {
          console.error(
            "Invalid data-tagular JSON:",
            targetElement.dataset.tagular,
            error
          );
        }
      }
    });
  }

  // Setup tracking for swipe events
  function setupSwipeTracking(htmlElement: HTMLElement) {
    let xDown: number | null = null;
    let yDown: number | null = null;

    const handleTouchStart = (evt: TouchEvent) => {
      const firstTouch = evt.touches[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    };

    const handleTouchMove = (evt: TouchEvent) => {
      if (!xDown || !yDown) {
        return; // Exit if we haven't recorded a touch start
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;

      // Determine swipe direction
      let actionOutcome: string | undefined;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          // Swipe Left
          actionOutcome = "SWIPED_LEFT";
        } else {
          // Swipe Right
          actionOutcome = "SWIPED_RIGHT";
        }
      } else {
        if (yDiff > 0) {
          // Swipe Up
          actionOutcome = "SWIPED_UP";
        } else {
          // Swipe Down
          actionOutcome = "SWIPED_DOWN";
        }
      }

      // Reset values
      xDown = null;
      yDown = null;

      // Use event delegation to find the element with data-tagular
      let targetElement = evt.target as HTMLElement;
      while (targetElement && !targetElement.dataset.tagular) {
        targetElement = targetElement.parentNode as HTMLElement;
      }

      // If a target with data-tagular is found, use it
      if (targetElement && targetElement.dataset.tagular) {
        try {
          // Parse the data-tagular attribute once
          const data = JSON.parse(targetElement.dataset.tagular);
          const { event: eventName, ...restPayload } = data;

          // Handle the ElementSwiped event
          if (eventName === "ElementSwiped") {
            const payload = { actionOutcome, ...restPayload };
            trackEvent(eventName, payload);
          }
        } catch (error) {
          console.error(
            "Invalid data-tagular JSON:",
            targetElement.dataset.tagular,
            error
          );
        }
      }
    };

    htmlElement.addEventListener("touchstart", handleTouchStart, false);
    htmlElement.addEventListener("touchmove", handleTouchMove, false);
  }

  // Setup tracking for view events
  // Must add data-view-track attribute to elements that should be tracked
  function setupViewTracking(htmlElement: HTMLElement) {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            let targetElement = entry.target as HTMLElement;

            if (targetElement && targetElement.dataset.tagular) {
              try {
                const data = JSON.parse(targetElement.dataset.tagular);
                const { event: eventName, ...restPayload } = data;
                trackEvent(eventName, restPayload); // Pass the eventName and payload to trackEvent
              } catch (error) {
                console.error("Invalid data-tagular JSON:", error);
              }
            }
          }
        });
      },
      { threshold: 0.1 }
    ); // Adjust threshold as needed

    // Observe existing elements with data-view-track
    const elementsToTrack = htmlElement.querySelectorAll("[data-view-track]");
    elementsToTrack.forEach(element => observer.observe(element));

    // Detect dynamically added elements
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === "childList") {
          mutation.addedNodes.forEach(node => {
            if (
              node instanceof HTMLElement &&
              node.hasAttribute("data-view-track")
            ) {
              observer.observe(node);
            }
          });
        }
      });
    });

    mutationObserver.observe(htmlElement, { childList: true, subtree: true });
  }

  // Initialize tracking setups
  if (tagularBody) {
    setupClickTracking(tagularBody);
    setupSwipeTracking(tagularBody);
    setupViewTracking(tagularBody);
  }
});
